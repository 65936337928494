import { persistCombineReducers } from 'redux-persist';
import { createFilter } from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage

import userReducer from './user';
import mainReducer from './main';
import appsReducer from './apps';


const userWhiteList = [
  'account',
];

const saveUserFilter = createFilter(
  'user',
  userWhiteList,
);

const persistConfig = {
  key: 'root',
  storage,
  transforms: [saveUserFilter],
  whitelist: ['user'],
};

const rootReducer = {
  user: userReducer,
  main: mainReducer,
  apps: appsReducer,
};

export default persistCombineReducers(persistConfig, rootReducer);
