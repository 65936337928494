/* eslint-disable default-case */
import produce from 'immer';
import { get } from '../common/requests';

export const GET_APPS = 'GET_APPS';
export const GET_APPS_ERROR = 'GET_APPS_ERROR';


const API_URLS = {
  config: 'REALM/portal/application/',
};

const initialState = {
  apps: [],
};

export default function reducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case GET_APPS:
        draft.apps = action.apps;
        break;
      case GET_APPS_ERROR:
        break;
    }
  });
}

export const refreshApps = () => async (dispatch) => {
  try {
    const apps = await get(API_URLS.config);
    dispatch({
      type: GET_APPS,
      apps,
    });
    return apps;
  } catch (error) {
    return {
      type: GET_APPS_ERROR,
    };
  }
};
