import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NavBarSNCF from '../../common/BootstrapSNCF/NavBarSNCF';
import DisplayApp from './displayApp';
import { refreshApps } from '../../reducers/apps';

import sncfReseauLogo from '@sncf/bootstrap-sncf.metier/dist/assets/img/brand/sncf-reseau-logo.png';
import dgexSolLogo from '../../assets/pictures/dgexsollogo.png';

const Home = () => {
  const dispatch = useDispatch();
  const apps = useSelector((state) => state.apps.apps);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    document.title = 'DGEX Solutions';
  }, []);

  useEffect(() => {
    dispatch(refreshApps());
  }, []);
  
  return (
   <>
     <NavBarSNCF appName="DGEX Solutions" username={user.username} logo={sncfReseauLogo} />
     <main className="mastcontainer mastcontainer-no-mastnav">
       <h1 className="text-center">
         <img src={dgexSolLogo} alt="DGEX Solutions logo" />
       </h1>
       <div className="cardscontainer">
         <div className="row">
           {apps.map((app) => (
             <DisplayApp app={app} key={app.name} />
           ))}
         </div>
       </div>
     </main>
   </>
 );
}

export default Home;
