import axios from 'axios';
import { API_GATEWAY } from '../config/config';

const formatPath = (path) => {
  if (path.startsWith('REALM')) {
    return `${API_GATEWAY.realm}${path.substring('REALM'.length)}`;
  } if (path.includes('SHARED')) {
    return `${API_GATEWAY.shared}${path.substring('SHARED'.length)}`;
  }
  return path;
};

const getAuthConfig = () => ({
  headers: {
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  },
});

export const get = async (path, params = undefined) => {
  const config = getAuthConfig();
  if (params) {
    config.params = params;
  }
  const res = await axios.get(formatPath(path), config);
  return res.data;
};

export const post = async (path, payload, config = {}) => {
  config = { ...getAuthConfig(), ...config };
  const res = await axios.post(formatPath(path), payload, config);
  return res.data;
};

export const patch = async (path, payload) => {
  const config = getAuthConfig();
  const { data } = await axios.patch(formatPath(path), payload, config);
  return data;
};

export const put = async (path, payload, config = {}) => {
  config = { ...getAuthConfig(), ...config };
  const { data } = await axios.put(formatPath(path), payload, config);
  return data;
};

export const deleteRequest = async (path) => {
  const config = getAuthConfig();
  const { data } = await axios.delete(formatPath(path), config);
  return data;
};

