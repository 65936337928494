import Card from '../../common/BootstrapSNCF/CardSNCF/CardSNCF';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ModalSNCF from '../../common/BootstrapSNCF/ModalSNCF/ModalSNCF';
import ModalHeaderSNCF from '../../common/BootstrapSNCF/ModalSNCF/ModalHeaderSNCF';
import ModalBodySNCF from '../../common/BootstrapSNCF/ModalSNCF/ModalBodySNCF';
import ModalFooterSNCF from '../../common/BootstrapSNCF/ModalSNCF/ModalFooterSNCF';
import './Home.css';

function hasRoles(obj, permissions) {
  if (permissions === undefined)
    return false;
  return permissions.includes(obj);
}

function DisplayApp({
  app
}) {
  const { t } = useTranslation();

  const permissions = useSelector((state) => state.user.permissions);

  const hasAccess = hasRoles(app.role_name, permissions);
  return hasAccess ? (
    <div className="col-sm-6 col-md-3 col-lg-2 mb-2">
        <Card img={app.image} title={app.name} link={app.url} externalLink />
    </div>
  ) : app.hidden ? (<></>) : (
    <>
      <div className="col-sm-6 col-md-3 col-lg-2 mb-2 grey" data-toggle="modal" data-target="#unauthorized">
        <Card img={app.image} title={app.name} link="#" />
      </div>
      <ModalSNCF htmlID="unauthorized">
        <ModalHeaderSNCF>
          <h5 className="h1 modal-title">{t('common.access.unauthorizedModalTitle')}</h5>
        </ModalHeaderSNCF>
        <ModalBodySNCF>
          <p>{t('common.access.unauthorizedModalBody')}</p>
        </ModalBodySNCF>
        <ModalFooterSNCF>
          <button type="button" className="btn btn-secondary" data-dismiss="modal">{t('common.modalButtonClose')}</button>
        </ModalFooterSNCF>
      </ModalSNCF>
    </>
  );
}

DisplayApp.propTypes = {
  app: PropTypes.object.isRequired,
};


export default DisplayApp;
